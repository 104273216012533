// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./global/cookies"
import "./global/turbo_progress_bar"
import "chartkick/chart.js"

import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()

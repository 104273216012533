import { Controller } from "@hotwired/stimulus"
import Carousel from "stimulus-carousel"

// Connects to data-controller="customer-review-carousel"
export default class extends Carousel {
  connect() {
    super.connect()
  }

  get defaultOptions() {
    return {
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      autoplay: {
        delay: 2700,
        disableOnInteraction: false,
      },
      speed: 1000,
      effect: "fade",
      navigation: {},
      pagination: {},
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="radio-selection-toggle"
const RADIO_SELECTION_TOGGLE = 'radio-selection-toggle'

export default class extends Controller {

  static classes = ["selected"]

  static targets = ["selected", "radioButton"]

  connect() {
    document.addEventListener(RADIO_SELECTION_TOGGLE, this.handleSelection.bind(this));
  }

  toggle(event) {
    event.stopImmediatePropagation()

    const selectionEvent = new CustomEvent(RADIO_SELECTION_TOGGLE, {
      detail: {
        name: event.target.name,
        value: event.target.value
      }
    });
    document.dispatchEvent(selectionEvent);
    return false;
  }

  handleSelection(event) {
    if (event.detail.name === this.radioButtonTarget.name) {
      const selectedClasses = this.selectedClass.split(" ");
      if (event.detail.value === this.radioButtonTarget.value) {
        this.selectedTarget.classList.add(selectedClasses);
      } else {
        this.selectedTarget.classList.remove(selectedClasses)
      }
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["trigger", "toggleable"]

  connect() {
    this.toggleDisplay()
    this.handleInitialSelection()
  }

  handleInitialSelection() {
    this.triggerTargets.forEach((trigger) => {
      const tagName = trigger.tagName.toLowerCase()
      const type = trigger.type

      if (tagName === 'select') {
        this.toggleElements(trigger.value)
      } else if (type === 'radio' && trigger.checked) {
        this.toggleElements(trigger.value)
      }
    })
  }

  toggleDisplay() {
    this.triggerTargets.forEach((trigger) => {
      const tagName = trigger.tagName.toLowerCase()
      const type = trigger.type

      if (tagName === 'select') {
        trigger.addEventListener('change', () => {
          this.toggleElements(trigger.value)
        })
      } else if (type === 'radio') {
        trigger.addEventListener('change', () => {
          if (trigger.checked) {
            this.toggleElements(trigger.value)
          }
        })
      } else {
        throw new Error(`Unsupported trigger target type: ${tagName} ${type}`)
      }
    })
  }

  toggleElements(triggerValue) {
    this.toggleableTargets.forEach((element) => {
      if (element.dataset.toggleValue === triggerValue) {
        element.style.display = 'block'
      } else {
        element.style.display = 'none'
      }
    })
  }
}

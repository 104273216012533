import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["codeInput", "errorMessage"]
  static values = { url: String }

  apply(event) {
    event.preventDefault();
    const discountCode = this.codeInputTarget.value;
    const applyDiscountUrl = this.urlValue;

    fetch(applyDiscountUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ discount_code: discountCode }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          location.reload();
        } else {
          this.errorMessageTarget.textContent = data.error;
          this.errorMessageTarget.classList.remove('hidden');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        this.errorMessageTarget.textContent = "Ett fel uppstod när rabatten skulle tillämpas.";
        this.errorMessageTarget.classList.remove('hidden');
      });
  }
}

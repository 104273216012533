import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";

export default class AdminSearchPage extends Controller {
  static targets = ["input"];
  static debounces = ['search'];

  connect() {
    useDebounce(this);
  }

  search() {
    const url = new URL(window.location);
    const query = this.inputTarget.value;
    url.searchParams.set("query", query);
    this.element.action = url.pathname + url.search;
    history.pushState({}, '', url); // Note: workaround to update the URL as action is being ignored when submitting as of Oct 11, 2023.
    this.element.requestSubmit();
  }
}

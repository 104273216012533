import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  toggle(event) {
    event.preventDefault()
    const modalId = event.currentTarget.getAttribute("data-modal-id")
    const modal = document.getElementById(modalId)
    if (modal.style.display === "none") {
      modal.style.display = "block"
    } else {
      modal.style.display = "none"
    }
  }
}

import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = process.env.NODE_ENV === "development";
window.Stimulus = application

import ScrollTo from "stimulus-scroll-to"
application.register("scroll-to", ScrollTo)

import Carousel from "stimulus-carousel"
application.register("carousel", Carousel)

export { application }

import { Controller } from "@hotwired/stimulus";

// Binds to every element with a `data-ui--ripple-target` attribute
// and creates a ripple effect when clicked.
//
// Attach the controller to a wrapping element like a form or even <body>.
// The controller will listen for clicks on the target elements.
//
// Example:
//   <button type="submit" data-ui--ripple-target="ripple" class="relative overflow-hidden">Submit</button>
export default class extends Controller {
  static targets = ["ripple"]

  connect() {
    console.log("Ripple connected", this.element);
    this.rippleTargets.forEach(target => {
      target.addEventListener("click", this.handleClick.bind(this));
    });
  }

  disconnect() {
    console.log("Ripple disconnected", this.element);
    this.rippleTargets.forEach(target => {
      target.removeEventListener("click", this.handleClick.bind(this));
    });
  }

  handleClick(event) {
    this.rippleEffect(event, event.currentTarget);
  }

  rippleEffect(event, target) {
    const circle = document.createElement("span");
    const diameter = Math.max(target.clientWidth, target.clientHeight);
    const radius = diameter / 2;

    // Calculate the click position relative to the target
    const rect = target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    // Style the circle
    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${x - radius}px`;
    circle.style.top = `${y - radius}px`;
    circle.style.position = "absolute";
    circle.style.borderRadius = "50%";
    circle.style.backgroundColor = "rgba(255, 255, 255, 0.3)";
    circle.style.pointerEvents = "none";

    // Apply Tailwind classes for animation
    circle.classList.add("absolute", "rounded-full", "bg-gray", "opacity-20");

    // Remove any existing ripple
    const existingRipple = target.querySelector(".animate-ripple");
    if (existingRipple) {
      existingRipple.remove();
    }

    target.appendChild(circle);

    // Trigger the animation by applying the Tailwind `animate-ripple` class
    requestAnimationFrame(() => {
      circle.classList.add("animate-ripple");
    });

    // Remove the element after the animation
    setTimeout(() => {
      circle.remove();
    }, 600);
  }
}

document.addEventListener('turbo:load', function () {
  document.querySelectorAll('[data-behavior="accept-cookie-consent"]').forEach(element => {
    element.addEventListener('click', () => {
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      document.cookie = 'cookie_consent_accepted=true; path=/; expires=' + expirationDate.toUTCString();
      document.getElementById('cookie-consent').classList.add('hidden');
    });
  });
});

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="subscriptions--pause-until"
export default class extends Controller {
  static targets = ["number", "date", "unitButton"]

  connect() {
    this.unit = "months"
    this.updateDate()
    this.updateUnitButtons()
  }

  increment() {
    this.changeNumber(1)
  }

  decrement() {
    this.changeNumber(-1)
  }

  pauseUntil() {
    const numberInput = this.numberTarget
    const newValue = parseInt(numberInput.value, 10)
    if (newValue >= 0 && this.isValidNumber(newValue)) {
      this.updateDate()
    } else {
      // Reset to a valid value if the input is invalid
      numberInput.value = 1
      this.updateDate()
    }
  }

  setUnit(event) {
    const newUnit = event.currentTarget.dataset.pauseUntilUnit
    if (newUnit !== this.unit) {
      this.convertNumber(newUnit)
      this.unit = newUnit
      this.updateDate()
      this.updateUnitButtons()
    }
  }

  changeNumber(delta) {
    const numberInput = this.numberTarget
    const newValue = parseInt(numberInput.value, 10) + delta
    if (newValue >= 0 && this.isValidNumber(newValue)) {
      numberInput.value = newValue
      this.updateDate()
    }
  }

  isValidNumber(value) {
    if (this.unit === "weeks" && value <= 52 && value > 0) {
      return true
    }
    if (this.unit === "months" && value <= 12 && value > 0) {
      return true
    }
    return false
  }

  convertNumber(newUnit) {
    const numberInput = this.numberTarget
    const currentNumber = parseInt(numberInput.value, 10)
    let baseDate = new Date()
    let targetDate = new Date(baseDate)

    // Adjust targetDate based on the current unit
    if (this.unit === "weeks") {
      targetDate.setDate(baseDate.getDate() + (currentNumber * 7))
    } else if (this.unit === "months") {
      targetDate.setMonth(baseDate.getMonth() + currentNumber)
    }

    // Convert targetDate to new unit
    let newNumber
    if (newUnit === "weeks") {
      const weeksDifference = Math.floor((targetDate - baseDate) / (1000 * 60 * 60 * 24 * 7))
      newNumber = Math.min(52, weeksDifference)
    } else if (newUnit === "months") {
      const monthsDifference = (targetDate.getFullYear() - baseDate.getFullYear()) * 12 + (targetDate.getMonth() - baseDate.getMonth())
      newNumber = Math.min(12, monthsDifference)
    }

    numberInput.value = newNumber
  }

  updateDate() {
    const number = parseInt(this.numberTarget.value, 10)
    const date = new Date()
    if (this.unit === "weeks") {
      date.setDate(date.getDate() + (number * 7))
    } else {
      date.setMonth(date.getMonth() + number)
    }
    this.dateTarget.value = date.toISOString().split('T')[0]
  }

  updateUnitButtons() {
    this.unitButtonTargets.forEach(button => {
      const activeClass = button.dataset.activeClass
      const inactiveClass = button.dataset.inactiveClass
      if (button.dataset.pauseUntilUnit === this.unit) {
        button.classList.add(...activeClass.split(' '))
        button.classList.remove(...inactiveClass.split(' '))
      } else {
        button.classList.add(...inactiveClass.split(' '))
        button.classList.remove(...activeClass.split(' '))
      }
    })
  }
}
